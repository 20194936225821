* {
  /*outline: 1px solid blue;*/
}


:root {
  --scrollbar-color: #aaa;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
  background: transparent;
}

::-webkit-scrollbar-track {
  width: 0.5em;
  background: transparent;
  border-radius: 100vw;
  z-index: 10000;
}
 
::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 100vw;
}

@supports (scrollbar-color: red blue) {
  * {
    scrollbar-color: var(--scrollbar-color) transparent;
    scrollbar-width: thin;
  }
}


.crop-resizer {
  background: rgba(200,200,200,0.5)
}

.crop-resizer:active {
  background: magenta;
}

.thick-border-on-hover:hover {
  border: 3px solid red;
}

.scalebar-text-container {
  display: flex;
  align-items: center;
  
}

.scalebar-structure-container > div {
  /*border: 1px solid black;*/
  height: 100%;
  justify-content: center;
  flex: 1;
}

.scalebar-preview {
  height: 2px !important;
  display: flex;
  align-items: center;

}

.invisible-without-grid-row-hover {
  opacity: 0;
}

.invisible-without-grid-row-hover:hover {
  opacity: 1;
}

.grid-row {
}

tr {
}

.grid-row:hover .invisible-without-grid-row-hover {
  opacity: 1 !important;
}

.figure-info-sidebar {

  border-left: 1px solid #eee;
  border-top:1px solid #eee;

  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14);
  background:#fafafa;

  position:absolute;
  top:0;
  right:0;
  height:100%;

  overflow-y:auto;
  width: 280px;

}

.selected-item-cover {
  width: 100%;
  height: 100%;
  background: #33aaff;
  opacity: 0.5;
  position: absolute;
  top:0;
  left:0;
  pointer-events: none;
  outline: 1px solid #33aaff;
}

.absolute-cover {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.show-when-parent-hovered {
  display: none;
}

.show-child-on-hover:hover .show-when-parent-hovered {
  display: block;
}

.outline {
  outline: 1px solid blue;
}



.rel {
  position: relative;
}


.add-circle {
  cursor: pointer;
  background: white;
  border-radius: 10px;
  padding: 0;
  margin: 0;
  color: #088c44;
  /* font-size: 10px;*/
}

.hidden-child {
  display: none;
}



.red-channel {
  filter: url(#red-channel-filter);
}

.green-channel {
  filter: url(#green-channel-filter);
}

.blue-channel {
  filter: url(#blue-channel-filter);
}

.red-green-channel {
  filter: url(#red-green-channel-filter);
}

.red-blue-channel {
  filter: url(#red-blue-channel-filter);
}

.green-blue-channel {
  filter: url(#green-blue-channel-filter);
}

.rgb-channel {
  filter: url(#rgb-channel-filter);
}

.magenta-tint {
  filter: url(#magentaTintFilter);
}




.magic-parent:hover .hidden-child {
  display: block;
}

.magic-parent:hover .expansion-item-id {
  padding: 3px;
  border-radius: 3px;
  background: #ccc;
  border: 2px solid #aaa;
}

.container-deleter:hover .deletable-container {
  border: 2px solid tomato;
   background: linear-gradient(45deg, #000 25%, transparent 25%),
              linear-gradient(-45deg, #000 25%, transparent 25%);
  background-image: repeating-linear-gradient(45deg, 
    #aaa 5px,
    #aaa 6px,
    pink 6px,
    pink 10px);
  /*background: pink !important;*/
}


.container-deleter:hover + .deletable-container {
  border: 2px solid tomato !important;
   background: linear-gradient(45deg, #000 25%, transparent 25%),
              linear-gradient(-45deg, #000 25%, transparent 25%);
  background-image: repeating-linear-gradient(45deg, 
    #aaa 5px,
    #aaa 6px,
    pink 6px,
    pink 10px);
  /*background: pink !important;*/
}

.expansion-item-container { }

.welcome-video-container {
  /*
   if we make it bigger, the login gets
   cut off sooner
  */
  width: 50%;
}


.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"].no-spinner {
  margin: 0;
  padding: 0;
  line-height: 0.7;
    -moz-appearance: textfield; /* Firefox */
}

@media ((max-width: 835px) or (max-height: 600px)) and (orientation: landscape) {
  .auth-container button {
    font-size:1.5vw !important;
  }

  .form-field {
    margin: 1vh !important;
  }

  .auth-container input {
    font-size: 1.5vw !important;
    height: 30px !important;
  }

  .auth-container button.btn-link {
    margin: 1vh !important;
    font-size: 1.5vw !important;
  }
}

@media (max-width: 910px) and (orientation: landscape) {
  .welcome-video-container {
    width: 50% !important;
  }
}


.auth-container button {
  font-size: 16px;
}

.auth-container input {
  font-size: 16px;
  height: 50px;
}

.auth-container .alternative {
  font-size: 15px !important;
}

.auth-container input::placeholder {
  padding: 10px;
  color: #ccc;
}

.auth-container {
  font-size: 24px;
  font-family: Roboto, Helvetica;
  max-width: 500px;
}

.auth-container div {
  font-family: Roboto, Helvetica;
}

.welcome-scene {
  /*font-family: Roboto, Lato, Helvetica;*/
}

.white-shaddow-container {
  background: white;
  padding: 8px;
  border-radius: 8px;
  box-shadow:0 2px 4px rgba(0 0 0 / 10%), 0 8px 16px rgba(0 0 0 / 10%);
  box-sizing: border-box;

}


.b-r {
  border: 1px solid red;
}

.discount-color {
  color: red;
}

.trial-end {
  text-align: left;
}

.trial-end h2,
.trial-end h5 {
  color: navy;
  font-variant: small-caps;
}

.text-body h1,
.text-body h2,
.text-body h3,
.text-body h4,
.text-body h5,
.text-body h6 {
  color: navy;
  font-variant: small-caps;
}

.trial-end p, li {
  font-size: 16px;
}


.children-with-margin div {
  margin: 20px;
}

/*
* {
  outline: solid 1px darkblue;
 }
*/

/*#tutorial-popup {
  transition: all 1s linear;
}*/

/*
[container=outer-annotation-mark-container]:hover {
  background: lime;
}


[input=mark-input]:hover {
  background: green;
}

[item=annotation-mark-container]:hover {
  background: green;
}
*/


.picker {
  /*width: 210px;*/
  background: #fff;
  border-radius: 9px;
}

.picker .react-colorful {
  width: auto;
}

.picker__swatches {
  display: flex;
  /*padding: 12px;*/
  flex-wrap: wrap;
}

.picker__swatch {
  width: 18px;
  height: 18px;
  margin: 4px;
  border: none;
  padding: 0;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}


.pricing-list {
  /*list-style-type: none; /* Removes the default bullet style */
}

.pricing-list li::marker {

  color: navy; /* Set the desired color here */
  font-size: 20px;
}

.signin-hover:hover{
  background: rgb(0, 129, 255);
  color: white;
}


.underline-hoverable {
  border-bottom: 5px solid transparent;

}

.underline-hoverable:hover {
  border-bottom: 5px solid white !important;
  border-radius: unset;
}

.white-underline {
  border-bottom: 5px solid white;
  border-radius: unset !important;
}

ol.password-change li  {
  margin-left: 20px;
  padding-left: 10px;
}

.no-pointer-hover-green {
  border: 3px solid red;
}

.no-pointer-hover-green:hover {
  border: 3px solid green;
}

.draw-line-path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 2s linear infinite;
  -webkit-animation: dash 2s linear infinite;
  -moz-animation: dash 2s linear infinite;
}

/*
@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}*/

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@-moz-keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.green-border-hoverable:hover{
  border: 3px solid lime;
}

.crisp-edges {
  shape-rendering: crispEdges;
}

.open-content {
  padding:15px;
  border: 1px solid #ccc;
}

.collapsible-protocol-content {
  border-radius: 10px;
  /*
  padding-left: 15px;
  padding-top: 15px;
  padding-right: 15px;
  */
  
  background: white;
}


.collapsible-protocol-content .collapsible-protocol-content {
  border: none;
  padding:none;

  #background: blue;
}



.crop-update-button {
  background: white;
  margin: 15px;
  margin-bottom: 5px;
  border-radius: 3px;

}

 .grey {
   color: grey;
 }


.tutorial-list > li {
  margin: 20px;
}

.scroll-shadows-vertical {
  overflow: auto;

  background:
    /* Shadow Cover TOP */
    linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ) center top,
    
    /* Shadow Cover BOTTOM */
    linear-gradient(
      rgba(255, 255, 255, 0), 
      white 70%
    ) center bottom,
    
    /* Shadow TOP */
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ) center top,
    
    /* Shadow BOTTOM */
    radial-gradient(
      farthest-side at 50% 100%,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ) center bottom;
  
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

.scroll-shadows-horizontal {
  overflow: auto;

  background:
    /* Shadow Cover TOP */
    linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ) center top,
    
    /* Shadow Cover BOTTOM */
    linear-gradient(
      rgba(255, 255, 255, 0), 
      white 70%
    ) center bottom,
    
    /* Shadow TOP */
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ) center top,
    
    /* Shadow BOTTOM */
    radial-gradient(
      farthest-side at 50% 100%,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ) center bottom;
  
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}


  
.target-search-result-text {
  font-size: 9px;
}

.abs-right {
  position: absolute;
  top: 0px;
  right: 0px;
  pointer-events: none;
}


.blinking{
    animation: blinkingText 2s infinite;
    color: white !important;
}
@keyframes blinkingText{
    0%{     background: #ccc;    }
    49%{    background: darkblue; }
    60%{    background: darkblue; }
    99%{    background: darkblue;  }
    100%{   background: #ccc;    }
}

.fs-alternating:nth-of-type(2n) {
  background: #fafafa !important;
}

.fs-alternating:nth-of-type(2n+1) {
  background: white;
}


.flex { 
  display:flex;
}

.v-center {
  align-items: center;
}

.h-center {
  justify-content: center;
}

.opaque-on-hover:hover {
  opacity: 1 !important;
  z-index: 5 !important;
}

.top-navbar-item {
  height: 100% !important;
  margin: 2px !important;
  padding: 10px !important;
  display: flex;
  justify-content: center;
}

.diagonal {

  background: linear-gradient(to top right,
    rgba(0,0,0,0) 0%,
    rgba(0,0,0,0) calc(50% - 0.8px),
    rgba(0,0,0,1) 50%,
    rgba(0,0,0,0) calc(50% + 0.8px),
    rgba(0,0,0,0) 100%);
}

.wide-slider {
  width: 150px !important;
}

.lane-box {
  fill:rgba(0,0,0,0);
}

.lane-box:hover{
  /*cursor:grab;*/
  fill:rgba(255,165,0,0.5);
}

.lane-box:active{
  /*cursor:grabbing;*/
  fill:rgba(255,165,0,0.5);
}




.drop * {pointer-events: none;}

.outline-on-hover:hover {
  outline: 5px solid #a6d3f7;
}

div.thick-on-hover:hover {
  background: pink !important;
}

path.thick-on-hover:hover {
  stroke-width:5;
  z-index:10;
}

.green-on-hover:hover {
  opacity: 0.5 !important;
}

.thick-stroke-on-hover {
  stroke-width:2;
  stroke: blue;
  //opacity: 0.3;
  fill: blue;
}
.thick-stroke-on-hover:hover {
  stroke-width:5;
  stroke: blue;
  fill: blue;
}

.thick-stroke-on-hover:active {
  stroke-width:3;
  stroke:red;
  fill:red;
}

.image-adjustment-button.large-font {
  font-size: 16px !important;
  padding: 4px !important;
}

.image-adjustment-button {
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid white;
  background: black;
  opacity: 0.5;
  color: white;
  font-size: 10px;
  padding: 2px;
  z-index: 4;
}


#root { 
  height: 100%;
}

.toolbar-action-button {
  padding:2px;
  margin:2px;
  border-radius:5px;
  text-align: center;
}

.disabled-button {
  color: #ccc;
}

.drop-image-box {
  border-radius:10;
  border:3px dashed #eee;
  width:100px;
  height:100px;
  color:#999;
  text-align: center;
  font-size: 18px;
}

.no-drag {
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.text-selectable {

  user-select: text;
  -moz-user-select: text;
  -webkit-user-drag: text;
  -webkit-user-select: text;
  -ms-user-select: text;

}

.red {
  background: #ff6961 !important;
}

.error-poppover .popover-body {
  background:red;
}

.hoverable-sibling ~ .visible-on-sibling-hover {
  visibility: hidden;
}

.hoverable .visible-on-parent-hover {
  visibility: hidden;
}

.hoverable-sibling:hover ~ .visible-on-sibling-hover {
  visibility: visible;
}

.hoverable-sibling:active ~ .visible-on-sibling-hover {
  visibility: visible;
}

.hoverable:hover .visible-on-parent-hover {
  visibility: visible;
}


.visible-on-hover {
  visibility: hidden;
}

.visible-on-hover:hover {
  visibility: visible !important;
}

.selected {
  background-color: #a6d3f7 !important;
}

.selected-blue {
  background-color: #a6d3f7 !important;
}
.item {
  font-family: sans-serif;
  border-top: 1px solid #ffffff;
  background-color: #eeeeee;
  border-bottom: 1px solid #999999;
  border-radius: 6px;
  margin-bottom: 4px;
  padding: 10px;
  padding-left: 8px;
  opacity: 1;
}
.dragged .item {
  opacity: 0.25;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #ffffff;
}


.logger-div {
  top:0px;
  left:0px;
  position:absolute;
  width:10px;
  height:10px;
}

.logger-div:hover {
  background:#ccc;
}
.blue {
  background: blue !important;
}

.svg-delete {
  pointer: cursor;
  fill: #eee;
}

.svg-delete:hover {
  fill: #ddd;
}




.collaborator-category:hover{
  background: #f0f0f0;
}

.blue-hoverable:hover {
  background: blue !important;
}

.blue-hoverable:hover:active {
  /*background: magenta !important;*/
}

.dark-active:active:hover {
  background: blue !important;
}


.extra-dark-hoverable:hover {
  background: #ddd !important;
}

.dark-hoverable:hover {
  background: #f0f0f0 !important;
}



.hoverable:hover {
  background: #e8e8e8;
}

.hoverable-blue {
  background: #bbd3fa;
}
.hoverable-blue:hover {
  background: #5190f5;
}

.hoverable-red:hover {
  background: #ffc4c4;
}

.hoverable-orange {
  background:#ffefde;
}

.hoverable-orange:hover {
  background: #ffd966;
}

.hoverable-green {
  background:#deffdf 
}

.hoverable-red {
  background:#ffdede
}

.hoverable-red:hover{
  background:#eb7171
}

.hoverable-green:hover {
  background: #8bd483;
}

.hoverable:drop {
  background:#f8f8f8;
}

.dark-hoverable .dark-selected {
  background: #ddd !important;
}

.hoverable.selected {
  background: #d8d8d8 !important;
}

.round-corners {
  border-radius: 10px;
}

.error-border {
  border: 3px solid red !important;
}

ol {
  padding-left: 1rem;
}

.modal-lg {
  max-width: 80% !important;
}

.opaque {
  opacity: 1 !important;
  background:'blue',

}

.selectable-table-group-end {
  border-right: 1px solid #dde2e7 !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.popover {
  max-width: 100% !important;
}

.blank-style {
  background: white !important;
  border: 0 !important;
}

.table-column-header {
}

.table-column-header:hover {
  background:pink;
}

.outside-table {
  border: none;
  background: 0;
}

td {
  user-select: none;
}
tr {
  user-select: none;
}

div {
  user-select: none;
}

.grabbable:hover {
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.grabbable:active:hover {
  cursor: grabbing;
}

.clickable {
  cursor: pointer;
}

.clickable-text {
  cursor: pointer;
}

.clickable-text:hover {
  text-decoration: underline;
}



.bold {
  font-weight: bold;
}


.align-center {
  text-align: center;
  vertical-align: middle;
}




.unselectable {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.btn-no-underline:hover {
  text-decoration: none !important;
}

.flex-expand {
  flex: 1;
}

.blue-link {
  z-index: 1;
}

.blue-link:hover {
  stroke: #a6d3f7 !important;
  stroke-width: 10px;
  z-index: 2;

}

.text-input {
  border: 0px;
}

.text-input:hover {
  border: 1px !important;
}
.editable-content:disabled {
  background: white;
}

.experiment-tree-node-unselected {
  border: 1px solid black;
  background: #dedede;
}

.experiment-tree-node-selected {
  color: white;
  font-weight: bold;

  background: #0053e9;
  border: 1px solid black;


}

.relative-center {
  position:relative;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.absolute-center {
  position:absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
}


.container-border {
  border: 1px groove grey;
}

.gel-overview-container {
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
}

.input-xs {
  height: 22px !important;
  padding: 2px 5px !important;
  font-size: 12px !important;
  line-height: 1.5 !important; /* If Placeholder of the input is moved up, rem/modify this. */
  border-radius: 3px !important;
}

.button-xs {
  height: 22px;
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
}

.modal-dialog {
  width: 90%;
  height: 90%;
}

footer {
  margin: 15px;
  text-align: center;
}

body, html {
  height: 100vh;
  flex: 1;
  width: 100vw;
  overscroll-behavior-x: none;
}

#app {
  height: 100%;
}

.no-padding {
  padding: 0 !important;
}

.scrollY {

  overflow-x: visible;
  overflow-y: scroll;

}

.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.1); }

.extend-height {
  height: 100%;
}

.fair-font-size{
  font-size: 20px;
}

.inventoryOptionsContainer{
  z-index: 100;
  background-color: white;
}

.light-border-bottom {
  border-bottom: 1px solid rgba(242, 242, 242)
}

.med-border-bottom {
  border-bottom: 1px solid rgba(180, 180, 180)
}

.light-border {
  border: 1px solid rgba(242, 242, 242)
}

.light-border {
  border: 1px solid rgba(242, 242, 242)
}

.full-width {
  width:100%;
}

.full-height-row-container {
  display: flex;
  flex-flow: row;
  height: 100%;
  width: 100%;

}

.get-started-plain {

  /*font-family: "Helvetica Neue";*/
  line-height: 30px;
  font-size: 19px;
  font-weight: normal;
  justify-content: center;

}

.get-started {

  /*font-family: "Helvetica Neue";*/
  line-height: 30px;
  font-size: 19px;
  font-weight: bold;
  justify-content: center;

}

.bold {
  font-weight: bold !important;
}

.get-started-left {
  color: white;
}

.no-margin {
  margin: 0 !important;
  margin-bottom: 10px !important;
  padding: 0 !important;
}

.full-height {
  height: 100%;
}

.loading{
  text-align: center;
  margin-top: 10%;
  color: #009efa;
}


.full-background {
  background-color: #009efa;
  height: 100%;
}

.modal-backdrop {
  opacity:0.5 !important;
}

.hidden {
  visibility: hidden;
}

.btn-group-xs > .btn, .btn-xs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

.remove-button {
  font-size: 18px !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 10px !important;
  text-align: center !important;
  line-height: 18px;
  padding-left: 1px;
  margin-left: 10px;
  margin-right: -5px;
}

.remove-button:hover {
  background: #eeeeee;
}

.hover-bold:hover {
  font-weight: bold !important;
}

.xxs span {
  margin: auto;
  text-align: center;
  font-weight: bold;
}

.gel-cutLine {
  width:  5px;

}

.grey-cutline {

  width:100%;
  height:  1px;
  border-bottom: 1px dashed grey;

}

.gel-cutLine-show {
  width:  5px;
  border-left: 1px dashed red;
}

.gel-cutLine-right-show {
  width:  0px;
  border-right: 0.25px dashed red;
}

.gel-lane-text{
  margin: auto;
  padding: 0 5%;

}

.gel-lane-container {
  height: 80px;
  width: 40px;
  margin: 3px;
  background-color: white;
  display: flex;
  padding:  5px;
  border-radius: 2px;
  border:  1px solid black;
  color:  black;
  font-size:  8px;
  font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;

}

.gel-well {
  min-width: 30px;
  height: 7px;
  border: 1px solid black;
}

.gel-laneSet-container {
  display:flex;

}

.flip-horizontally {
  transform: scaleX(-1);
}

.gel-laneSet {
  background-color: #f5f3f0;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #aaaaaa;
}

.gel-display{
  display: flex;
}

.gel {
  margin: 15px;
}

.gel-text-rotate {
  transform: rotate(-135deg);
}

.close {
  font-size: 12px;
  cursor: pointer;
  position: relative;
  top: 50%;
  right: 0%;
  padding: 1px;
  transform: translate(0%, -50%);
}

.close:hover {background: #bbb;}

#root {
  font-size: 12px;

}

.section-mark{
  background-color: #e6e6e6 !important;
}

.for-loop{
  background-color: white;
}

.protocol-items-container{
  width: 70%;
  display: inline-block;
  overflow-y: scroll;
  overflow-x: visible;
  padding: 15px;
  height: 100%;

}

.protocol-steps-container{
  width: 30%;
  display: inline-block;
  overflow-y: scroll;
  overflow-x: visible;
  padding: 15px;
  height: 100%;
}

.circle-add-button{
  border: 1px solid grey;
  background-color: white;
  border-radius: 50%;
}

.well {
  margin: 2px;
  height: 10px;
  width: 10px;
  display: inline-block;
  font-size: 10px;
}

.small-item-title{
  font-size: 8px;
  padding: 0px;
  text-align: center;
  font-weight: bold;
}

.well-medium {
  margin: 2px;
  height: 9px;
  width: 9px;
  display: inline-block;
  font-size: 12px;
}

.well-small {
  margin: 2px;
  height: 9px;
  width: 9px;
  display: inline-block;
  font-size: 8px;
}

.well-circle{
  border: 1px solid grey;
  background-color: white;
  border-radius: 50%;
}

.membrane {
  background-color: #fefefe;
  box-shadow: 2px 2px 3px grey;
}
.light-bg {
  background-color: #fefefe !important;
  border: 1px solid #f2f2f2 !important;
}

.less-light-bg {
  background-color: #f6f6f6 !important;
  border: 1px solid #f2f2f2 !important;
}
.sg-col {
  display: flex;
  flex-flow: column;
}

.sg-row {
  display: flex;
  flex-flow: row;
}

.sg-row-reverse {
  display: flex;
  flex-flow: row-reverse;
}



.tiny-padding {
  padding: 2px !important;
}

.tiny-margin {
  margin: 2px !important;
}

.tiny-margin-right {
  margin-right: 2px !important;
}

.well-row {
  display: flex;
  flex-flow: row;
}

.list-group-item.active.light {
  background-color: #a6d3f7 !important;
  border-color: gray !important;
  color: black;
}

.step-footer-unfocused{
  height: 10px; 
}

.step-footer-focused{
}

select.type{
  width: 10px;
}

.form-div{
  border: 1px solid #cccccc;
}

.valid-entry{
  background-color: #c9fabe !important;
}

.hover-true{
  background-color: #cccccc;
}

.hover-false{

}

.comment-header{
  display: flex;
  flex-flow: row;
  width: 100%;
}

.bordered{
  border: 1px solid #cccccc;

}

.sidebar-text{
}

.light-vbar{
  margin-top: -1px;
  border: 1px solid #f2f2f2;
  border-top: 0;
  z-index: 1;
  background-color: #fefefe;
}

.vertical-bar{
  margin-top: -1px;
  border: 1px solid #f2f2f2;
  border-top: 0;
  z-index: 1;
  background-color: #fefefe;
}

.thin-horizontal-line {
  background: #cccccc;
  height: 1px;
  width: 100%;
  cursor: ns-resize;
}

.thin-vertical-line{
  background: #cccccc;
  width: 1px;
  cursor: col-resize;
}

.thin-vertical-line-expandable {
  border: unset;
}

.thin-vertical-line-expandable:hover {
  width: 3px;
}

.thin-vertical-line-expandable:active {
  width: 3px;
}

.divider-bottom {
  border-bottom: 1px solid #cccccc;
}

.vertical-line{
  background: #cccccc;
  width: 5px;
  cursor: col-resize;
}

.vertical-div-center{
  height: 100%;
  transform: translate(0,50%) 
}

.rotate-left{
  transform: rotate(-90deg);

}

.vertical-center{
  position: relative;
  top: 50%;
  transform: translate(0,-50%);
  /*transform: translate(-35%, -50%) rotate(-90deg);*/
}

.small-font {
  font-size: 10px !important;
}

.bottom-line {
  border-bottom: 1px solid black;
}

.center-horizontally {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.navbar-left{
  margin-left: 10%;
  padding: 10px;
}

.navbar-right{
  padding-right: 100px;

}

.bump-from-top{
  margin-top: 50px;
  margin-bottom: 50px;
}

.bump-from-right{
  margin-right: 100px;
}


.selected-actor{
  background-color: #0c8df5;
}



.selected-error{
  background-color: #f7a6a6;
}

.item-div-container{
  flex-flow: row;
  display: flex;
}

.item-div{

  margin: 2px;
  padding: 0px;
  border: 1px solid #cccccc;
  border-radius: 0.25rem;

}

.step-item-div{

  margin-top: 3px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 3px;
  border: 1px solid #cccccc;
  border-radius: 0.25rem;

}



#inv {
  margin: 10px;
  display: flex;
  flex-flow: row;
  height: 100%;
}

.table{
  overflow: scroll;
}

#inv-form, #inv-search{
  width: 50%;
  display: inline-block;

}

#inv-search{
}

.small-padding-left {
  padding-left: 10px;
}

.small-margin{
  margin: 10px;
}

.small-margin-right{
  margin-right: 10px;
}

.small-margin-left{
  margin-left: 10px;
}


.small-margin-top{
  margin-top: 10px;
}

.small-margin-bottom{
  margin-bottom: 10px;
}

.fair-margin{
  margin: 20px;
}

.fair-margin-right{
  margin-right: 20px;
}

.fair-margin-left{
  margin-left: 20px;
}


.fair-margin-top{
  margin-top: 20px;
}

.fair-margin-bottom{
  margin-bottom: 20px;
}

.comment-date{
  float: right;
}

.small-padding{
  padding: 10px;
}

.fair-padding{
  padding: 20px;
}

.fair-padding-left {
  padding-left: 20px;
}

.fair-padding-right {
  padding-right: 20px;
}




.one-line{

  display: flex;
  flex-flow: row;

}

.white-background{
  background-color: white;
}

.half-split-component{
  display: flex;
  flex-flow: row;
}

.half-split-container{
  display: flex;
  flex-flow: row;
  height: 100%;
}

.half-container{
  margin: 10px;
  display: flex;
  flex-flow: row;
}

.sidebar{
  width: 3%;
  display: inline-block;
  overflow: scroll;
  height: 100%;
}

.main-pane{
  width: 97%;
  display: inline-block;
  overflow: scroll;
  padding: 15px;
  height: 100%;
}

.third-container{
  margin: 10px;
  display: flex;
  flex-flow: row;
}

.third{
  width: 33%;
  display: inline-block;
  overflow: scroll;
  padding: 15px;
}

.row-component{
  display: inline-block;
  margin:0px;
}

.metadata-container{
  width: 45%;
  display: inline-block;
  overflow: scroll;
  padding: 15px;
}
.results-table{
  width:100%;
  display: inline-block;
  overflow: scroll;
  padding: 15px;
}



.two-thirds{
  width: 67%;
  display: inline-block;
}
.experiment-list-container{
  width: 35%;
  display: inline-block;
  overflow: scroll;
  padding: 15px;
}

.experiment-view-container{
  width: 100%;
  display: inline-block;
  overflow: scroll;
  padding: 15px;
}

.quarter-container{
  margin:10px;
  display: flex;
  flex-flow: row;
}

.quarter{
  width: 25%;
  display: inline-block;
  overflow: scroll;
}

.button-centered{
  z-index: 3;
  position: absolute;
  margin-top: -15px;
  top: 0px;
  left: 2%;
}

.centered{
  text-align: center;
  margin: auto;
}

.half {
  width: 50%;
  display: inline-block;
  overflow-y: auto;
  overflow-x: visible;
  height: 100%;
}

.half-no-scroll{
  width: 50%;
  display: inline-block;
  padding: 15px;
  height: 100%;
  overflow: visible;
}

.half-split-container-component{
  width: 50%;
  display: inline-block;
  overflow: scroll;
}

.dashboard-preview{
  height: 40%;
  overflow: scroll;
}

.dashboard-preview-full{
  height: 100%;
  overflow: scroll;
}

.active-row{
  background-color: #0052e9 !important;
  color: white !important;
}

.blue{
  background-color: blue;
}

.pink{
  background-color: pink !important;
}
.experiment-data{
  margin: 10px;
  border-style: line;
}

.manual-protocol-item-container{

  width: 40%;
  display: flex;
  flex-flow: row;
  background-color: #ebf2f5;
  margin: 10px;
  text-align: center;
  margin: auto;
  margin-block-end: 10px;
}

.delete-container{
  position: absolute; 
  right: 0px;
  top: 0px;
}

.protocol-form-item{
  display: flex;
  flex-flow: row;
}

.form-wrapper-container{
  width: inherit;
  display: flex;
  flex-flow: row;
}

.form-wrapper{
  background-color: #ebf2f5;
  padding: 10px;
}

.item-form-container{

}

.dynamic-text {
  font-size: 80%;
}

.image-caption {
  height: 20px;
  white-space: nowrap;
}

.gallery-image-caption-container {
  border: 1px solid #f3f3f3;
}

.gallery-group {
  height: 100%;
  border: 1px solid gray;
}

.gallery-image-container {
  /*height: 100%;*/
}

.grid-container {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto 1fr;
  border: 1px solid gray;
}



.no-border {
  border: unset !important;
}

.no-border-left {
  border-left: unset !important;
}

.no-border-top {
  border-top: unset !important;
}

.no-border-bottom {
  border-bottom: unset !important;
}

.no-border-right {
  border-right: unset !important;
}



.grid-item {
  border: 1px solid grey;
  font-size: 10px;
  text-align: center;
  white-space: nowrap;
}


/* ALL OF THE FOLLOWING CSS IS TAKEN FROM
 * W3SCHOOLS FOR THE ROUND SLIDER!
 */

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .App {
    font-family: sans-serif;
    text-align: center;
  }

  .react-contextmenu {
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 16px;
    color: #373a3c;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    outline: none;
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease !important;
  }

  .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
  }

  .react-contextmenu-item {
    padding: 3px 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #373a3c;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    cursor: pointer;
  }

  .react-contextmenu-item.react-contextmenu-item--active,
  .react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
  }

  .react-contextmenu-item.react-contextmenu-item--disabled,
  .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    color: #878a8c;
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
  }

  .react-contextmenu-item--divider {
    margin-bottom: 3px;
    padding: 2px 0;
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
  }
  .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
  }

  .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
  }

  .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
  }

  .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
  }

  .example-multiple-targets::after {
    content: attr(data-count);
    display: block;
  }

  .relative-position {
    position: relative !important;
  }



  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    font-size: 14px;
    margin-top: -9px;
    margin-left: -6px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
  }

  .dropdown-submenu:hover>.dropdown-menu {
    display: block;
  }

  .dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
  }

  .dropdown-submenu:hover>a:after {
    border-left-color: #fff;
  }

  footer .container {
    padding-top: 80px;
  }


  /*
   * END OF w3schools slider stuff
   */

  .img-container {
    height: 200px;
  }

  .img-preview {
    height: 200px;
  }

  .no-border {
    border: none;
  }

  .resize-active {
    background: #bbbbbb !important;
    background: blue !important;
  }


  .second-grid-container > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 10px 0;
    font-size: 12px;
    max-width: 50px;

  }

  .second-grid-container {
    text-overflow: wrap;
    overflow: hidden;
    grid-template-columns: auto auto auto auto auto auto;
    grid-template-rows: auto auto auto auto;
    width: 50%;
    display: grid;
    grid-gap: 10px;
    background-color: #2196F3;
    padding: 10px;
  }


